/* eslint-disable no-param-reassign */
import { arrayify } from '@ethersproject/bytes'
import { parseBytes32String } from '@ethersproject/strings'
import { Currency, currencyEquals, ETHER, Token } from '@pancakeswap/sdk'
import { createSelector } from '@reduxjs/toolkit'
import { GELATO_NATIVE } from 'config/constants'
import { CHAIN_ID } from 'config/constants/networks'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TokenAddressMap } from 'state/types'
import {
    combinedTokenMapFromActiveUrlsSelector,
    combinedTokenMapFromOfficialsUrlsSelector,
    useUnsupportedTokenList,
    useWarningTokenList,
} from '../state/lists/hooks'
import { NEVER_RELOAD, useSingleCallResult } from '../state/multicall/hooks'
import useUserAddedTokens, { userAddedTokenSelector } from '../state/user/hooks/useUserAddedTokens'
import { isAddress } from '../utils'
import { useBytes32TokenContract, useTokenContract } from './useContract'

const mapWithoutUrls = (tokenMap: TokenAddressMap) =>
    Object.keys(tokenMap[CHAIN_ID]).reduce<{ [address: string]: Token }>((newMap, address) => {
        newMap[address] = tokenMap[CHAIN_ID][address].token
        return newMap
    }, {})

const allTokenSelector = createSelector(
    [combinedTokenMapFromActiveUrlsSelector, userAddedTokenSelector],
    (tokenMap, userAddedTokens) => {
        // console.info('%c allTokenSelector', 'color: red', tokenMap, userAddedTokens)
        return (
            userAddedTokens
                // reduce into all ALL_TOKENS filtered by the current chain
                .reduce<{ [address: string]: Token }>(
                    (tokenMap_, token) => {
                        tokenMap_[token.address] = token
                        return tokenMap_
                    },
                    // must make a copy because reduce modifies the map, and we do not
                    // want to make a copy in every iteration
                    mapWithoutUrls(tokenMap),
                )
        )
    },
)

const allOfficialsAndUserAddedTokensSelector = createSelector(
    [combinedTokenMapFromOfficialsUrlsSelector, userAddedTokenSelector],
    (tokenMap, userAddedTokens) => {
        return (
            userAddedTokens
                // reduce into all ALL_TOKENS filtered by the current chain
                .reduce<{ [address: string]: Token }>(
                    (tokenMap_, token) => {
                        tokenMap_[token.address] = token
                        return tokenMap_
                    },
                    // must make a copy because reduce modifies the map, and we do not
                    // want to make a copy in every iteration
                    mapWithoutUrls(tokenMap),
                )
        )
    },
)

/**
 * Returns all tokens that are from active urls and user added tokens
 */
export function useAllTokens(): { [address: string]: Token } {
    return useSelector(allTokenSelector)
}

/**
 * Returns all tokens that are from officials token list and user added tokens
 */
export function useOfficialsAndUserAddedTokens(): { [address: string]: Token } {
    return useSelector(allOfficialsAndUserAddedTokensSelector)
}

export function useUnsupportedTokens(): { [address: string]: Token } {
    const unsupportedTokensMap = useUnsupportedTokenList()
    return useMemo(() => mapWithoutUrls(unsupportedTokensMap), [unsupportedTokensMap])
}

export function useWarningTokens(): { [address: string]: Token } {
    const warningTokensMap = useWarningTokenList()
    return useMemo(() => mapWithoutUrls(warningTokensMap), [warningTokensMap])
}

export function useIsTokenActive(token: Token | undefined | null): boolean {
    const activeTokens = useAllTokens()

    if (!activeTokens || !token) {
        return false
    }

    return !!activeTokens[token.address]
}

// Check if currency is included in custom list from user storage
export function useIsUserAddedToken(currency: Currency | undefined | null): boolean {
    const userAddedTokens = useUserAddedTokens()

    if (!currency) {
        return false
    }

    return !!userAddedTokens.find((token) => currencyEquals(currency, token))
}

// parse a name or symbol from a token response
const BYTES32_REGEX = /^0x[a-fA-F0-9]{64}$/

function parseStringOrBytes32(str: string | undefined, bytes32: string | undefined, defaultValue: string): string {
    return str && str.length > 0
        ? str
        : // need to check for proper bytes string and valid terminator
        bytes32 && BYTES32_REGEX.test(bytes32) && arrayify(bytes32)[31] === 0
            ? parseBytes32String(bytes32)
            : defaultValue
}

// undefined if invalid or does not exist
// null if loading
// otherwise returns the token

// WEIYA: 设置代币
export function useToken(tokenAddress?: string): Token | undefined | null {
    const { chainId } = useActiveWeb3React()
    const tokens = useAllTokens()

    const address = isAddress(tokenAddress)

    const tokenContract = useTokenContract(address || undefined, false)
    const tokenContractBytes32 = useBytes32TokenContract(address || undefined, false)
    const token: Token | undefined = address ? tokens[address] : undefined

    const tokenName = useSingleCallResult(token ? undefined : tokenContract, 'name', undefined, NEVER_RELOAD)
    const tokenNameBytes32 = useSingleCallResult(
        token ? undefined : tokenContractBytes32,
        'name',
        undefined,
        NEVER_RELOAD,
    )
    const symbol = useSingleCallResult(token ? undefined : tokenContract, 'symbol', undefined, NEVER_RELOAD)
    const symbolBytes32 = useSingleCallResult(token ? undefined : tokenContractBytes32, 'symbol', undefined, NEVER_RELOAD)
    const decimals = useSingleCallResult(token ? undefined : tokenContract, 'decimals', undefined, NEVER_RELOAD)

    return useMemo(() => {
        if (token) return token
        if (!chainId || !address) return undefined
        if (decimals.loading || symbol.loading || tokenName.loading) return null
        if (decimals.result) {
            return new Token(
                chainId,
                address,
                decimals.result[0],
                parseStringOrBytes32(symbol.result?.[0], symbolBytes32.result?.[0], 'UNKNOWN'),
                parseStringOrBytes32(tokenName.result?.[0], tokenNameBytes32.result?.[0], 'Unknown Token'),
            )
        }
        return undefined
    }, [
        address,
        chainId,
        decimals.loading,
        decimals.result,
        symbol.loading,
        symbol.result,
        symbolBytes32.result,
        token,
        tokenName.loading,
        tokenName.result,
        tokenNameBytes32.result,
    ])
}

export function useCurrency(currencyId: string | undefined): Currency | Token | null | undefined {
    const isBNB = currencyId?.toUpperCase() === 'BNB' || currencyId?.toLowerCase() === GELATO_NATIVE
    const token = useToken(isBNB ? undefined : currencyId)
    return isBNB ? ETHER : token
}
